import { Image } from '@atoms/index';
import texts from '@components/TaxOptimiser/en.json';

const TaxOptimiserButton = ({
    buttonText = texts?.SetUpFreeConsultation,
    onClick = () => {},
    disabled = false,
    suffix = false,
    width = '',
}) => {
    return (
        <div
            className={`${
                !disabled ? 'p-[1px] bg-tax-optimizer-button-border' : ''
            }  rounded-lg ${width}`}
        >
            <button
                className={`${
                    !disabled
                        ? `bg-tax-optimizer-button cursor-pointer`
                        : 'bg-tax-optimizer-button-disabled cursor-not-allowed border border-gray-600'
                } p5-semibold xl:p5-medium text-basicWhite px-6 xl:px-4 py-3 xl:py-2 rounded-lg flex gap-2 items-center justify-center ${width}`}
                onClick={onClick}
                disabled={disabled}
            >
                {buttonText}
                {suffix ? (
                    <Image
                        src="/images/tax-optimiser/right-arrow.svg"
                        className="w-5 h-5"
                    />
                ) : null}
            </button>
        </div>
    );
};

export default TaxOptimiserButton;
