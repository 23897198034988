import { Modal, Text } from '@atoms/index';
import { Button } from '@ui/atoms/design-system';

const MessageModal = ({
    title,
    message,
    buttonText,
    onClick,
    isError,
    show,
    onClose,
    isTaxOptimiserLogin,
}) => (
    <Modal
        show={show}
        onClose={onClose}
        width="max-w-[440px]"
        label={title}
        labelBorderClass="border-none"
        labelSize=" "
        labelParaClass={`${
            isTaxOptimiserLogin
                ? 'text-basicWhite h6-semibold sm:h5-semibold'
                : 'text-primary-500 h5-semibold'
            }
        `}
        labelPadding="pb-4"
        roundedClass="rounded-xl"
        marginClass="p-4 md:p-6"
        paddingClass=""
        isError={isError}
        mainWrapperClassName={
            isTaxOptimiserLogin ? 'backdrop-blur-sm inset-0' : ''
        }
        modalColorClass={
            isTaxOptimiserLogin
                ? 'text-basicWhite bg-basicBlack border border-gray-500'
                : 'text-basicBlack bg-basicWhite'
        }
        isDarkTheme={isTaxOptimiserLogin}
        children={
            <div>
                <Text
                    content={message}
                    className={`mb-4 p5-regular ${
                        isTaxOptimiserLogin
                            ? 'text-basicWhite'
                            : 'text-gray-900'
                    }`}
                />
                <Button
                    buttonType="primary"
                    buttonSize="regular"
                    buttonText={buttonText}
                    onClick={onClick}
                    widthClass="w-full"
                />
            </div>
        }
    />
);

export default MessageModal;
